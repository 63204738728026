
import GeneralData from "@/modules/providers/components/GeneralData.vue";
import useProvider from "@/modules/providers/composables/useProvider";
import toastr from "toastr";
import {ref} from "vue";
import router from "@/router";

export default {
    components: {GeneralData},
    setup() {
        const {clearForm, providerForm, createProvider, catalogs} = useProvider()
        const sending = ref(false)

        clearForm();

        return {
            sending,
            catalogs,
            cancel: () => router.push({name: 'providers'}),
            onSubmit: async () => {
                try {
                    sending.value = true
                    const {ok, message} = await createProvider(providerForm.value)

                    if (ok) {
                        toastr.success('Su solicitud se ha procesado correctamente.')
                        await router.push({name: 'providers'})
                    } else {
                        await toastr.error(message)
                    }
                } finally {
                    sending.value = false
                }
            }
        }
    }
}
