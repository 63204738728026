
import {onMounted, ref, watch} from "vue";
import useProvider from "@/modules/providers/composables/useProvider";
import usePanel from "@/composables/usePanel";

const {providerForm} = useProvider();

export default {
    props: ['catalogs'],
    setup() {
        const {showPanel, togglePanel} = usePanel()

        const name = ref('')
        const status = ref('')

        onMounted(() => {
            name.value = providerForm.value.name
            status.value = providerForm.value.status
        })

        watch(name, val => providerForm.value.name = val)
        watch(status, val => providerForm.value.status = val)

        return {
            showPanel,
            togglePanel,
            name,
            status,
            providerForm
        }
    }
}
